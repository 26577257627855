<template>
  <header>
    <Head>
      <!-- Google tag (gtag.js) -->
      <component :is="'script'" async src="https://www.googletagmanager.com/gtag/js?id=G-852X59QW4R"></component>
      <component :is="'script'">
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          window.dataLayer.push(arguments);
        }

        (function initializeGTag() {
          const gaScript = document.createElement('script');
          gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-852X59QW4R';
          gaScript.async = true;
          gaScript.onload = () => {
            gtag('js', new Date());
            gtag('config', 'G-852X59QW4R');
          };
          document.head.appendChild(gaScript);
        })();

      </component>
      <meta charset="utf-8"/>
      <title>Citron Digital - Site vitrine / Application web</title>
      <meta name="description" content="Citron Digital - Site vitrine / Application web"/>
      <meta name="keywords" content="Spécialiste à Toulouse en création de sites vitrines, applications web et logiciels sur mesure. Optimisation SEO intégrée pour un meilleur référencement. Un processus simple : conception, design, développement, livraison et maintenance."/>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
      <meta name="imageLink" content="https://citrondigital.fr/logo.png">
      <meta name="charset" content="UTF-8">
      <meta name="robots" content="index, follow">
      <meta itemprop="image" content="https://citrondigital.fr/logo.png">
      <!-- Open Graph / Facebook -->
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://citrondigital.fr/"/>
      <meta property="og:title" content="Citron Digital"/>
      <meta property="og:description" content="Site vitrine / Application web"/>
      <meta property="og:image" content="https://www.citrondigital.fr/logo.png"/>
      <meta property="og:image:secure_url" content="https://www.citrondigital.fr/logo.png" />
      <!-- Twitter -->
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content="https://citrondigital.fr"/>
      <meta property="twitter:title" content="Citron Digital"/>
      <meta property="twitter:description" content="Site vitrine / Application web"/>
      <meta property="twitter:image" content="https://citrondigital.fr/logo.png"/>
    </Head>
  </header>
  <div class="app-container">
    <main class="home" v-if="this.$route.name === 'Home'">
      <router-view/>
    </main>
    <div v-else>
      <!--<HeaderComponent/>-->
      <custom-nav class="custom-nav" logo_img="img/citron_digital_name.png" img_route="/home" :link_routes=link_routes />
      <main class="app-content">
         <router-view/>
      </main>
      <footer-component/>
    </div>
  </div>
</template>

<script>
  import {Head} from "@vueuse/head";
  import FooterComponent from "@/components/FooterComponent.vue";
  import CustomNav from "@vues/v-common/src/nav/Nav.vue";

  export default {
    components: {
      CustomNav,
      FooterComponent,
      // eslint-disable-next-line vue/no-reserved-component-names
      Head,
    },
    data: function () {
      return {
        isPhone: false,
        isTablet: false,
        isDesktop: false,
        link_routes: [
          {name: "SITE WEB", route: "/site-web"},
          {name: "APPLICATION", route: "/application-web"},
          {name: "PORTFOLIO", route: "/portfolio"},
          {name: "CONTACT", route: "/contact"}
        ],
      }
    },
    methods: {
      setResolutionType() {
        const MAX_RES_PHONE = 700;
        const MAX_RES_TABLET = 1200;
        this.isPhone = window.outerWidth < MAX_RES_PHONE;
        this.isTablet = MAX_RES_PHONE <= window.outerWidth && window.outerWidth < MAX_RES_TABLET;
        this.isDesktop = MAX_RES_TABLET <= window.outerWidth;
      }

    },
    mounted() {
      this.setResolutionType();
      window.onresize = function () {
        this.setResolutionType();
      }.bind(this);
    }
  }

</script>

<style lang="stylus">
  @import '~/src/common/style/common.styl';
  @import '~/src/common/style/fonts.styl';

  .app-container
    display flex
    flex-direction column
    justify-content center
    background linear-gradient(to top right, #0b1323, #273346)
    min-height calc(100% - 150px - 60px)
    main
      margin-top 60px
      min-height calc(100vh - 3em - 6rem - 60px)
      display grid !important
      &.home
        min-height 100vh

  .custom-nav
    --NAV_HEIGHT 60px
    --THEME_COLOR_NAV_HEADER #030e25
    li::before
      display none


</style>