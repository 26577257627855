<template>
  <section class="advices-section">
    <h2 class="title">Les avis sur Citron Digital</h2>
    <div class="advices-container">
      <article v-for="advice in advices" :key="advice" class="advice-container">
        <div class="advice">
          <p class="username">{{advice.username}}</p>
          <div class="stars">
            <img width="17px" height="17px" v-for="n in advice.stars" :key="n" src="/img/yellow_star.svg" alt="étoile" loading="lazy"/>
            <img width="17px" height="17px" v-for="n in 5-advice.stars" :key="n" src="/img/blank_star.svg" alt="étoile" loading="lazy"/>
          </div>
          <p class="description">{{advice.description}}</p>
        </div>
      </article>
    </div>
  </section>
</template>

<script>

export default {
  name: 'AdvicesComponent',
  data: function () {
    return {
      advices: [
          {
            username:"OufleureCrea",
            stars: 5,
            description: "J’ai fait appel à Citron Digital pour créer mon site web et j’en suis très satisfaite. Le travail a été réalisé rapidement, avec une communication claire du début à la fin. Il s’est occupé du nom de domaine et de l’hébergement, tout en s’adaptant parfaitement à mon budget. \nProfessionnel compétent avec une solide expertise technique, Franck Besson saura répondre à vos besoins avec efficacité. Si vous cherchez quelqu’un de confiance à qui confier votre projet (votre “bébé”), vous êtes à la bonne adresse. Vous pouvez compter sur son sérieux, son savoir-faire et son engagement."
          },{
            username:"Ouzahra Halima",
            stars: 5,
            description: "Il m’a aidée à développer ma boutique en ligne sur WordPress avec professionnalisme et expertise. Grâce à son intervention, la performance de mon site s’est nettement améliorée, tout comme son référencement naturel (SEO). Il m’a également accompagnée dans le changement de nom de domaine en s’occupant de tout avec rigueur. Très compétent, je ne regrette absolument pas notre collaboration et le recommande vivement !"
          }
      ],
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/constants.styl';

  .advices-section
    background-color #b1ac3a
    padding 1em
    display flex
    flex-direction column
    align-items center
    .title
      padding-top 0.5em
      width 100%
      text-align center
      color #162537
      font-size 2em
    .advices-container
      margin-top 1em
      display flex
      justify-content center
      height 100%
      width 100%
      .advice-container
        width 33%
        display flex
        flex-direction column
        align-items center
        padding-top 2em
        padding-bottom 2em
        .advice
          background-color: #113f59;
          margin-right 10px
          margin-left 10px
          border-radius 3px
          padding: 15px;
          display flex
          flex-direction column
          align-items flex-start
          min-height 250px
          .stars
            margin-top 5px
            margin-bottom 5px
            display flex
            flex-direction row
            justify-content center
            img
              height 17px
              width 17px
          p
            color white
            margin 0
            &.username
              font-weight bold
            &.description
              margin-top 5px
              font-size 15px

  @media (max-width: 700px)
    height auto !important
    padding-bottom 3em !important
    .advices-container
      flex-direction column !important
      .advice-container
        padding-top 2em !important
        width auto !important
</style>