<template>
  <div class="page-container">
    <div class="contact-container">
      <h1>Contactez-moi !</h1>
      <p>
        Vous avez une question ? un projet ? Ou tout autre chose, n'hésitez pas ! Voici comment me contacter :
      </p>
      <a class="hyperlink" href="https://www.linkedin.com/in/franck-besson-fr/">
        <button>
          <img src="img/linkedin.png" alt="Citron Digital"/>
          <span>Mon profil</span>
        </button>
      </a>
      <a class="hyperlink" href="mailto:contact@citrondigital.fr">
        <button>
          <img src="img/mail.png" alt="Citron Digital"/>
          <span>contact@citrondigital.fr</span>
        </button>
      </a>
      <a class="hyperlink" href="tel:0614851819">
        <button>
          <img src="img/phone.svg" alt="Citron Digital" style="height: 30px"/>
          <span>06 14 85 18 19</span>
        </button>
      </a>
    </div>
  </div>
</template>

<script>
    import {useHead} from "@vueuse/head";

    export default {
        name: 'ContactView',
        mounted() {
          useHead({
            title: 'Citron Digital - Contact | Échangeons sur votre projet',
            meta: [
              { name: 'description', content: 'Besoin d’un site vitrine, d’une application web ou d’un logiciel ? Contactez Citron Digital pour échanger sur votre projet et obtenir un accompagnement personnalisé.'},
              { name: 'keywords', content: 'contact Citron Digital, devis site web, création application web, développement logiciel, demande de projet, échange digital, Toulouse'}
            ]
          });
        }
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';
.page-container
  min-height 100%
  padding-left 10%
  padding-right 10%
  display flex
  flex-direction column
  justify-content center
  align-items center
  .contact-container
    max-width 380px
    a
      width 100%
      button
        width 100%
        box-shadow 0 0 5px #113f59
        background-color #113f59
        border none
        cursor pointer
        margin-top 25px
        height 70px
        border-radius 5px
        display flex
        align-items center
        padding-left 10px
        span
          font-size 1.1em
          padding-left 15px
          font-weight bold
          color white
        img
          width 45px

.back-container
  display flex
  justify-content: center;
  .back
    height 4.5em
    fill FONT_COLOR

</style>