<template>
  <nav class="header">
    <router-link :to="'/'" class="citron"><span><strong>CITRON</strong> DIGITAL</span></router-link>
    <router-link :to="'/site-web'"><span><strong>SITE WEB</strong></span></router-link>
    <router-link :to="'/application-web'"><span><strong>APPLICATION</strong></span></router-link>
    <router-link :to="'/portfolio'"><span><strong>PORTFOLIO</strong></span></router-link>
    <router-link :to="'/contact'"><span><strong>CONTACT</strong></span></router-link>
  </nav>
</template>

<script>
    export default {
        name: 'HeaderComponent',
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';
a
  height 75px // 150px pour la bannière
  display block
  text-decoration none
.header
  display flex
  padding-left 6%
  justify-content flex-start
  align-items center
  background-color #030e25 // Commenter pour la bannière
  height 75px // 150px pour la bannière
  width calc(100% - 6%)
  a
    display flex
    flex-direction column
    justify-content center
    padding-right 1em
    margin-right 2%
    *
      margin 0
    &.citron
      margin-right 4%
      span
        font-size 1.8em
        strong
          color THEME_COLOR_SECONDARY
  .separator
    height 5.2em
    width 1px
    background-color #0b1323
    margin-right 1.5rem
    margin-left 1.5rem
  .logo-container
    padding-left 1em
    display flex
    justify-content: center;
    align-items: center;
    width 8em
    height 100%
    img
      width 100%
</style>