<template>
  <section class="portfolio-section">
    <h1>Mon portfolio</h1>

    <div class="blocs-container">

      <nav class="header-tab">
        <a id="experiences" :class="{'selected' : this.tab === 'experiences'}" @click="this.tab = 'experiences'"><span>Expériences</span></a>
        <a id="formation" :class="{'selected' : this.tab === 'formation'}" @click="this.tab = 'formation'"><span>Formations</span></a>
      </nav>

      <div class="header-separator"></div>

      <TrainingComponent v-if="this.tab === 'formation'"/>
      <ExperiencesComponent v-else/>

    </div>
  </section>
  <advices-component/>
  <call-to-action-component/>
</template>

<script>
    import ExperiencesComponent from "@/components/ExperiencesComponent.vue";
    import TrainingComponent from "@/components/TrainingComponent.vue";
    import {useHead} from "@vueuse/head";
    import AdvicesComponent from "@/components/AdvicesComponent.vue";
    import CallToActionComponent from "@/components/CallToActionComponent.vue";

    export default {
        name: 'PortfolioView',
        components: {CallToActionComponent, AdvicesComponent, TrainingComponent, ExperiencesComponent},
        data: function () {
          return {
            tab: 'experiences'
          }
        },
        mounted() {
          useHead({
            title: 'Citron Digital - Portfolio | Création de Sites Web et Applications à Toulouse',
            meta: [
              { name: 'description', content: 'Découvrez le parcours professionnel de Franck Besson, expert en création de sites web, applications et logiciels. Consultez mes expériences et formations dans le domaine du digital.'},
              { name: 'keywords', content: 'portfolio, CV Franck Besson, expert en développement web, création de site web, application web, SEO, formation en digital, Toulouse'}
            ]
          });
        }
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';
.portfolio-section
  padding-left 10%
  padding-right 10%
  margin-bottom 50px
.back-container
  display flex
  justify-content center
  align-items center
  padding-top 20px
  width 300px
  img
    width 60px
  .back
    height 4.5em
    fill FONT_COLOR

.header-separator
  margin-bottom 15px
  height 1px
  background-color FONT_COLOR
  opacity 0.3
  width 100%

.separator
  margin-top 30px
  margin-bottom 15px
  height 1px
  background-color FONT_COLOR
  opacity 0.3
  width 35%

strong
  color THEME_COLOR_SECONDARY

.header-tab
  display flex
  justify-content space-around
  a
    display flex
    align-items center
    cursor pointer
    span
      font-size 1.4em
    text-decoration none
    *
      color FONT_COLOR_CLICKABLE
  .selected
    background-color #424c67
    border-top-left-radius 15px
    border-top-right-radius 15px
    padding 10px
    span
      font-size 1.7em
      color FONT_COLOR
</style>