<template>
  <section>
    <h2>Besoin d'un devis ou de discuter ?</h2>
    <p>Echangeons autour de votre projet</p>
    <router-link :to="'/contact'" class="hyperlink">
      <button>
        <span>GRATUIT ET SANS ENGAGEMENT</span>
        <img src="img/arrow.svg" alt="Citron Digital" style="height: 30px"/>
      </button>
    </router-link>
  </section>
</template>

<script>

export default {
  name: 'CallToActionComponent',
}
</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/constants.styl';

  section
    background: linear-gradient(to top right, #0d1b2c, #285065);
    padding-top 60px
    padding-bottom 60px
    display flex
    flex-direction column
    align-items center
    padding-right 25px
    padding-left 25px
    h2
      margin-bottom 0
      margin-top 0
    p
      margin-bottom 20px
    a
      max-width 380px
      button
        width 100%
        box-shadow 0 0 5px #113f59
        background-color #ffc107
        border none
        cursor pointer
        margin-top 25px
        height 70px
        border-radius 5px
        display flex
        align-items center
        padding-left 10px
        span
          font-size 1.2em
          padding-left 15px
          font-weight bold
          color #162537
        img
          width 45px

  @media (max-width: 700px)
    height auto !important
</style>