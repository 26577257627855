<template>
    <article class="bloc-container">
      <div class="text-container">
        <h2><strong>IMERIR</strong> - Manager de Systèmes Informatique et Robotique</h2>
        <h3>De 2016 à 2019 à Perpignan</h3>
        <div>
          <p>L'imerir forme des spécialistes de la robotique et plus généralement des spécialistes de l'informatique appliquée aux nouvelles technologies (cybersécurité, intelligence artificielle, internet des objets, robotique humanoïde, robotique industrielle et cobotique, fabrication additive, machines et objets connectés…).</p>
          <ul>
            <li>Professionnalisation : </li>
            <ul>
              <li>2016 - Stage à IKCOM</li>
              <li>2017 - Stage au laboratoire de Banyuls-Sur-Mer</li>
              <li>De 2017 à 2019 - Alternance à Renault</li>
            </ul>
            <li>Enseignement : </li>
            <ul>
              <li>Management</li>
              <li>Informatique</li>
              <li>Robotique</li>
              <li>Spécialité "Smart Object and Cloud" (I.O.T.)</li>
            </ul>
          </ul>
        </div>
      </div>
      <div class="image-container">
        <a href="https://www.iut-larochelle.fr/"><img class="rounded" src="img/imerir.png" alt="IMERIR" data-holder-rendered="true"></a>
      </div>
    </article>

    <div class="separator"></div>

    <article class="bloc-container">
      <div class="text-container">
        <h2><strong>UPVD</strong> - License 3 en calcul numérique</h2>
        <h3>De 2016 à 2017 à Perpignan</h3>
        <div>
          <p>License 3 réalisée en parallèle avec la première année à l'IMERIR.</p>
          <ul>
            <li>Développement Android</li>
            <li>Mathématiques</li>
            <li>Paradigme de programmation</li>
          </ul>
        </div>
      </div>
      <div class="image-container">
        <a href="https://www.iut-larochelle.fr/"><img src="img/upvd.png" alt="UPVD" data-holder-rendered="true"></a>
      </div>
    </article>

    <div class="separator"></div>

    <article class="bloc-container">
      <div class="text-container">
        <h2><strong>IUT Informatique</strong> - Spécialisation informatique embarquée</h2>
        <h3>De 2014 à 2016 à La Rochelle</h3>
        <div>
          <p >DUT informatique réalisé sur deux ans, dans le but de de rejoindre par la suite un cycle ingénieur.</p>
          <ul>
            <li>Réalisation de multiples projets étudiant</li>
            <li>Projet réalisé en réponse à un besoin professionel (pour l'entreprise TECDRON)</li>
            <li>Spécialité informatique embarquée</li>
          </ul>
        </div>
      </div>
      <div class="image-container">
        <a href="https://www.iut-larochelle.fr/"><img src="img/iut_la_rochelle.png" alt="IUT Informatique" data-holder-rendered="true"></a>
      </div>
    </article>

    <div class="separator"></div>

    <article class="bloc-container">
      <div class="text-container">
        <h2><strong>Lycée Jean De Lattre de Tassigny</strong> - BAC STI2D</h2>
        <h3>De 2012 à 2014 à La Roche-sur-Yon</h3>
        <div>
          <p >J'ai continué ma lancée sur un BAC STI2D avec la spécialité SIN</p>
          <ul>
            <li>Projet de fin d'année : un terrarium automatisé (avec un Arduino) </li>
            <li>Mention bien</li>
            <li>Spécialité SIN (Système Informatique et Numérique)</li>
          </ul>
        </div>
      </div>
      <div class="image-container">
        <a href="https://www.iut-larochelle.fr/"><img class="rounded" src="img/dlt.png" alt="Lycée Jean De Lattre de Tassigny" data-holder-rendered="true"></a>
      </div>
    </article>

    <div class="separator"></div>

    <article class="bloc-container">
      <div class="text-container">
        <h2><strong>Lycée François Truffaut</strong> - Seconde générale</h2>
        <h3>De 2011 à 2012 à Challans</h3>
        <div>
          <p>Durant cette seconde générale je découvre un peu plus l'informatique.</p>
          <ul>
            <li>Option CIT</li>
          </ul>
        </div>
      </div>
      <div class="image-container">
        <a href="https://www.iut-larochelle.fr/"><img class="rounded" src="http://ekladata.com/L_WVhl6-ZyQX772aENASMHH4Tak@150x216.jpg" alt="Lycée François Truffaut" data-holder-rendered="true"></a>
      </div>
    </article>

    <div class="separator"></div>

    <article class="bloc-container">
      <div class="text-container">
        <h2><strong>Collège Saint Joseph</strong></h2>
        <h3>De 2007 à 2011</h3>
        <div>
          <p >C'est durant cette période que je découvre l'informatique, que je fais mes premiers pas avec visual studio.</p>
          <ul>
            <li>Stage dans le métier d'électricien (EVIDENT)</li>
            <li>Stage dans une boîte d'informatique (DIS Informatique)</li>
          </ul>
        </div>
      </div>
      <div class="image-container">
        <a href="https://www.iut-larochelle.fr/"><img class="rounded" src="img/logo_saint_joseph.jpg" alt="Collège Saint Joseph" data-holder-rendered="true"></a>
      </div>
    </article>
</template>

<script>

export default {
  name: 'TrainingComponent',
}
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';

.blocs-container
  display flex
  flex-direction column

  .bloc-container
    display flex
    flex-direction column
  .image-container
    display flex
    align-items: center;
    justify-content: center;
    height 10rem
    img
      max-height 10rem
      max-width 10rem
      &.rounded
        border-radius 15px

  .separator
    margin-top 30px
    margin-bottom 15px
    height 1px
    background-color FONT_COLOR
    opacity 0.3
    width 35%

  .header-separator
    margin-bottom 15px
    height 1px
    background-color FONT_COLOR
    opacity 0.3
    width 100%

strong
  color THEME_COLOR_SECONDARY

.header-tab
  display flex
  justify-content space-around
  a
    text-decoration none
    *
      color FONT_COLOR_CLICKABLE

@media only screen and (min-width: DESKTOP_SIZE)
  .bloc-container
    flex-direction row !important
    justify-content space-between
    .text-container
      width 100%
    .image-container
      height inherit
      width 30rem
      img
        max-height 13rem
        max-width 13rem
</style>